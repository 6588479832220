import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/SchoolProgram/RepublicDay23/1.jpg';
// import p2 from 'assests/Photos/SchoolProgram/RepublicDay23/2.jpg';
// import p3 from 'assests/Photos/SchoolProgram/RepublicDay23/3.jpg';
// import p4 from 'assests/Photos/SchoolProgram/RepublicDay23/4.jpg';
// import p5 from 'assests/Photos/SchoolProgram/RepublicDay23/5.jpg';
// import p6 from 'assests/Photos/SchoolProgram/RepublicDay23/6.jpg';
// import p7 from 'assests/Photos/SchoolProgram/RepublicDay23/7.jpg';
// import p8 from 'assests/Photos/SchoolProgram/RepublicDay23/8.jpg';
// import p9 from 'assests/Photos/SchoolProgram/RepublicDay23/9.jpg';
// import p10 from 'assests/Photos/SchoolProgram/RepublicDay23/10.jpg';







import { Typography } from '@mui/material';
import Container from 'components/Container';

import SidebarArticles from 'views/SchoolProgram/components/SidebarArticles/SidebarArticles';

const RepublicDay23 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/RepublicDay23/1.webp`;
const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/RepublicDay23/2.webp`;
const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/RepublicDay23/3.webp`;
const p4 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/RepublicDay23/4.webp`;
const p5 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/RepublicDay23/5.webp`;
const p6 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/RepublicDay23/6.webp`;
const p7 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/RepublicDay23/7.webp`;
const p8 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/RepublicDay23/8.webp`;
const p9 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/RepublicDay23/9.webp`;
const p10 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/school-programs/RepublicDay23/10.webp`;

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source:p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },
      {
        src: p9,
        source: p9,
        rows: 1,
        cols: 1,
      },
      {
        src: p10,
        source: p10,
        rows: 1,
        cols: 1,
      },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      74th Republic Day
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
Date: 26 January 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The constitution was adopted by the Indian Constituent Assembly on 26 November 1949 and came into force on 26
January 1950, proclaiming India as a Republic. It is a day of national rejoicing and a red-letter day in our country. 
      <br/>
      The students of National Public School, Yeshwanthpur celebrated the 74th Republic Day on Thursday, 26 January 2023
with patriotic fervour, gaiety, pride and zeal to commemorate the people behind the making of our Constitution. The
celebrations commenced with the unfurling of the ‘Tiranga’ by the School Principals followed by a patriotic rendition
of the National Anthem. The Principals received the grand salute as contingents marched in perfect symphony with
great enthusiasm and patriotism in their hearts. It was followed by a spectacular Cultural Show which befitted the
historic day.
   <br/><br/>
  The programme focussed on the theme, ‘Jan Bhagidari’ which means participation of the common people and
emphasised on ‘Nari Shakti’. Principals reiterated that every girl child is important and advised the students to
inculcate the value of respecting every woman in the form of mother, sister or friend. The day concluded on a solemn
note with the students paying tribute to the martyrs and an unspoken promise to empower everyone under the
umbrella of our Constitution.
<br/><br/>
Class assemblies presented a skit that showcased the hardships of our soldiers, patriotic songs and dance recitals that left the audience spell-bound.
<br/></Typography>
      </Box>
      <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
With faith in our hearts and independence in our thoughts, let's salute the Nation!
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>

<br/>
        </Typography>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default RepublicDay23;